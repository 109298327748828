import * as React from 'react';

import {SanityCategory, SanityEditorial, Maybe} from 'types/generated';

import CategoryTemplate from './index';

export default ({
  pageContext,
  location,
}: {
  pageContext: {
    category: SanityCategory;
    categoryArticles: [Maybe<SanityEditorial>];
    limit: number;
    currentPage: number;
    numPages: number;
  };
  location: Location;
}): React.ReactElement => (
  <CategoryTemplate
    category={pageContext.category}
    categoryArticles={pageContext.categoryArticles}
    currentPage={pageContext.currentPage}
    limit={pageContext.limit}
    numPages={pageContext.numPages}
    location={location}
  />
);
